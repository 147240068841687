<template>
    <div>
        <img :src="navimg" class="navimg">

        <div style="margin: 20px 0 10px 0">
            <span style="font-weight: 400;font-size: 24px;">AI图片工具</span>
            <span style="font-size: 15px;color: #666666;margin-left: 15px;">快速创作出你想要的图片</span>
        </div>
        <div class="ptools">
            <CradPicture v-for="item in datalist" :key="item.id" :item="item" class="pitem" />
        </div>
    </div>
</template>

<script>

import CradPicture from '@/components/CradPicture';
export default {
    data() {
        return {
            navimg: require('@/assets/Toolsimg/navPictureTools.svg'),
            datalist: [
                {
                    id: 1, name: "图文去重", doc: "高效智能美化图片", img: "https://top.xhsbds.com/assets/img/picture/twqc.svg",
                    onitem: () => {
                        console.log("点击了图文去重");
                        this.$router.push({
                            path: '/pImageRemoval',
                        })
                    }
                },
                {
                    id: 2, name: "AI扩图", doc: "一键生成海量场景图", img: "https://top.xhsbds.com/assets/img/picture/AIkt.svg",
                    onitem:async () => {
                        //判断是否有权限
                        let isUse = await this.$utils.getaiCanUse();
                        if(!isUse){
                            this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                            return ;
                        }
                        console.log("点击了AI扩图配图");
                        this.$router.push({
                            path: '/pictrueMod',
                        })
                    }
                },
                {
                    id: 3, name: "小红书主图", doc: "一键生成小红书头图", img: "https://top.xhsbds.com/assets/img/picture/xhszt.svg ",
                    onitem: () => {
                        console.log("点击了小红书配图");
                        this.$router.push({
                            path: '/xhs',
                        })
                    }
                },
                {
                    id: 4, name: "智能改图", doc: "一键修改，不留痕迹", img: "https://top.xhsbds.com/assets/img/picture/zngt.svg",
                    onitem: async () => {
                        //判断是否有权限
                        let isUse = await this.$utils.getaiCanUse();
                        if(!isUse){
                            this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                            return ;
                        }
                        console.log("点击了智能改图");
                        this.$router.push({
                            path: '/pictureUp',
                        })
                    }
                },
                {
                    id: 5, name: "变清晰", doc: "告别渣画质", img: "https://top.xhsbds.com/assets/img/picture/bqx.svg",
                    onitem:async () => {
                        //判断是否有权限
                        let isUse = await this.$utils.getaiCanUse();
                        if(!isUse){
                            this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                            return ;
                        }
                        console.log("点击了变清晰");
                        this.$router.push({
                            path: '/pclear',
                        })
                    }
                },
                { id: 6, name: "智能抠图", doc: "3秒智能识别除背景", img: "https://top.xhsbds.com/assets/img/picture/znkt.svg" },
                { id: 7, name: "图片编辑", doc: "轻松设计质感图片", img: "https://top.xhsbds.com/assets/img/picture/tpbj.svg" },
                { id: 8, name: "智能设计", doc: "高效高质量", img: "https://top.xhsbds.com/assets/img/picture/AIznsj.svg" },
                {
                    id: 9, name: "商品设计", doc: "线稿秒变创意效果图", img: "https://top.xhsbds.com/assets/img/picture/AIspsj.svg",
                    onitem:async () => {
                        //判断是否有权限
                        let isUse = await this.$utils.getaiCanUse();
                        if(!isUse){
                            this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                            return ;
                        }
                        console.log("点击了商品设计");
                        this.$router.push({
                            path: '/pcommodity',
                        })
                    }
                },
                { id: 10, name: "祛除水印", doc: "在线智能祛除水印", img: "https://top.xhsbds.com/assets/img/picture/qcsy.svg",
                    onitem: () => {
                        console.log("点击了祛除水印");
                        this.$router.push({
                            path: '/pRwatermark',
                        })
                    }
                },
                {
                    id: 11, name: "模版中心", doc: "海量模版创作不用愁", img: "https://top.xhsbds.com/assets/img/picture/mbzx.svg",
                    onitem: () => {
                        console.log("点击了智能改图");
                        this.$router.push({
                            path: '/pCenter',
                        })
                    }
                }
            ]


        }
    },
    components: {
        CradPicture
    }
}
</script>

<style lang="less" scoped>
.ptools {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
}

.navimg {
    width: 100%;border-radius: 24px;
}</style>