<template>
  <div class="main">
    <img src="~@/assets/image/bg.png" alt="bg" class="bg">
    <nav class="navbar">
      <img src="@/assets/logo.png" alt="logo" class="logo">
      <span class="navtitle">{{ title }}</span>
    </nav>
    <main class="content">
      <div class="detail ">
        <div style="position: relative;">
          <div class="content-text">本地薯本地生活服务上共创平台</div>
          <img src="~@/assets/image/line.png" class="content-img" alt="图片" srcset="">
        </div>
        <div class="explain">
          专注本地生活技术赋能 | 助力实体商家降本增效
        </div>
        <div class="card-deck">
          <template>
            <card-content v-for="(item, i) in dataUrl" :key="i" :data="item" />
          </template>

        </div>
      </div>
    </main>
    <footer class="footer">
      <div class="tfooter">
        <div class="gfooter">
          <a target="_blank" href="https://www.xiaohongshu.com/"
            class="css-1jqi9o1 css-e2zsxc dyn typograhpy">关于本地薯<!----><!----></a>
          <div class="divier-container">
            <div class="divier-content"><!---->|</div>
          </div>
        </div>
        <div class="gfooter"><a target="_blank" href="https://www.xiaohongshu.com/protocols/community-rule"
            class="css-1jqi9o1 css-e2zsxc dyn typograhpy" data-v-085001a9="">社区规范<!----><!----></a>
          <div class="divier-container">
            <div class="divier-content"><!---->|</div>
          </div>
        </div>
        <div class="gfooter">
          <a target="_blank" href="https://www.xiaohongshu.com/content_dispute"
            class="css-1jqi9o1 css-e2zsxc dyn typograhpy" data-v-085001a9="">侵权投诉<!----><!----></a><!---->
        </div>
      </div>
      <div class="typograhpy">蜀ICP备2023022274号-1 Copyright © 2019-2024
        成都本地薯信息科技有限公司<!----><!----></div>
    </footer>
  </div>
</template>

<script>
import CardContent from './CardContent/CardContent.vue'
export default {
  data() {
    return {
      name: 'index',
      title: '本地薯',
      dataUrl: [
        {
          title: '小红书酒旅入驻',
          logo: '/image/buying.png',
          notes: '酒店旅行，让旅行不一般',
          img: '/image/buying-img.png',
          link: 'https://narada.timesaas.cn/#/login',
          ocolor: '#B4B0F0',
          color: 'rgba(229,229,253,0.41)'
        }, {
          title: '小红书团购入驻',
          logo: '/image/wine.png',
          notes: '寻找本地生活，生活更多彩',
          img: '/image/wine-img.png',
          link: 'https://cj-mall.kaidang.cn/index.php?s=/store/passport/login',
          ocolor: '#FDC494',
          color: 'rgba(248,239,219,0.45)'
        }
      ]
    }
  },
  components: {
    CardContent
  }
}
</script>

<style scoped>
.navbar {
  width: 100%;
  height: 80px;
  background: #FFFFFF;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start
}

.logo {
  width: 46px;
  height: 46px;
  margin-left: 20px
}

.navtitle {
  padding-left: 8px;
  font-size: 24px;
  font-family: 'Source Han Sans, Source Han Sans';
  font-weight: 500;
  color: #3D3D3D;
  line-height: 29px;
}

.main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  display: flex;
  justify-self: center;
  align-items: center;
  flex-direction: column;
}

.detail {
  top: 7vw;
  position: relative;
  min-width: 650px;
  width: 70%;
  height: 350px;
}

.content-text {
  font-size: 46px;
  font-family: 'Source Han Sans, Source Han Sans';
  font-weight: 550;
  color: #1A1A1A;
  line-height: 67px;
  position: relative;
  z-index: 10;
}

.content-img {
  position: relative;
  right: 0;
  z-index: 1;
  top: -15px;
}

.explain {
  font-size: 18px;
  font-family: 'Source Han Sans, Source Han Sans';
  font-weight: 400;
  color: #898787;
  line-height: 29px;
  position: relative;
  z-index: 10;
}

.card-deck {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between
}

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}


.footer {

  position: absolute;
  bottom: 0px;
  width: 100%;
  /* background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
    color: #333;*/
}

.tfooter {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 12px;
}

.gfooter {
  margin-bottom: 0px;
  color: rgb(153, 153, 153);
  font-size: 12px;
  display: flex;
  flex-direction: row;
}

.divier-content {
  background-color: rgb(232, 232, 232);
  margin-left: 16px;
  margin-right: 16px;
  height: 16px;
}

.typograhpy {
  color: rgb(153, 153, 153);
  text-align: center;
  font-size: 12px;
}</style>