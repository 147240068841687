<template>
    <div>
      3333333 
    </div>
  </template>
  
  <script>
  import { utlisApi } from "@/utils/utils";
  //在需要使用的页面引入，并实例化对象
  import CktDesign from "@chuangkit/chuangkit-design";
  export default {
    name: 'BlankCanvas',
    data() {
      return {
        openid: '',
        option: {},
        utlisApi: new utlisApi()
      }
    },
    methods: {
    },
    mounted() {
      // this.openid = this.$route.params.openid;
      this.utlisApi.openid = "123456";
      // 点击拉起
      const myparams = {
        template_id: '365561', // 模板id
      }
      this.option = this.utlisApi.getDesignEditor(myparams);
      console.log(this.option, "打印一下option");
      this.design = new CktDesign(this.option);
      this.design.open();
    }
  }
  </script>
  
  <style></style>