<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <Gateway/> -->
    <RouterView />
  </div>
</template>

<script>
// import Gateway from './components/Gateway/index.vue';
// import HelloWorld from './components/HelloWorld.vue';

export default {
  name: 'App',
  components: {
    // Gateway
  },

}
</script>

<style lang="less" scoped>

#app {
  margin: 0px;
  padding: 0px;
  height: 100%;
} 
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
