<template>
    <div>
        <el-dialog title="设备管理" :visible.sync="dialogVisible" width="540px" :before-close="handleClose">
            <el-table :data="datalist">
                <el-table-column property="deviceModel" label="设备名称" width="150"></el-table-column>
                <el-table-column property="deviceType" label="设备类型" width="150">
                    <template slot-scope="scope">
                        <span> 
                            <el-tag type="success" >{{scope.row.deviceType}}</el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column property="isstate" label="状态" width="150"> 
                    <template slot-scope="scope">
                        <span>
                            <el-tag type="danger" v-if="scope.row.isstate">当前设备</el-tag>
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="50">
                    <template slot-scope="scope">
                        <el-button @click="handleClick(scope.row)" type="text" size="small">解绑</el-button>
                    </template>
                </el-table-column>

            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import  { getFingerprint } from '@/utils/utils.js';
export default {
    data() {
        return {
            dialogVisible: false,
            gridData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }],
            datalist: []
        };
    },
    
    //  mounted() {
    //     this.init();
    // },
    //监听vuex设置状态值shouldOpenDeviceManage是否发生变化
    watch: {
        $store: {
            handler: function (val) {
                // console.log("val", val);
                if (val.state.shouldOpenDeviceManage == true) {
                    this.init();
                    this.dialogVisible = true;
                }
            },
            deep: true
        }
    },
    methods: {
        handleClose() {
            this.$store.commit('setshouldOpenDeviceManage', false);
            this.dialogVisible = false;
        },
        async init() {
            let device_id = await getFingerprint();
            this.$newapi.deviceList().then(res => {
                console.log("设备列表", res);
                if (res.code == 200) {
                    let temp = [];
                    temp = res.data.map(item => {
                        let objtemp = item.device_info ? JSON.parse(item.device_info) : {};
                        return {
                            id: item.id,
                            user_id: item.user_id,
                            device_id: item.device_id,
                            device_info: item.device_info ? JSON.parse(item.device_info) : {},
                            create_user_id: item.create_user_id,
                            is_current: item.is_current,
                            deviceType: objtemp.deviceType,
                            deviceModel: objtemp.deviceModel,
                            isstate:device_id == item.device_id ? true : false
                        };
                    });

                    console.log("设备列表", res, temp);
                    this.datalist = temp;
                }
            });
        },
        handleClick(done) {
            this.$confirm('解绑后该设备退出登录，确认解绑？')
                .then(_ => {
                    console.log("点击了确定", _);
                    //判断是解绑当前设备，还是解绑其他设备
                    if(!_){
                        return;
                    }
                    // 解绑当前设备则退出登录
                    if(done.isstate){
                        this.$newapi.deviceUnbind({id:done.id}).then(res => {
                            console.log("解绑", res);
                            if (res.code == 200) {
                                this.LogOut();
                            }else{
                                this.$message.error('解绑失败');
                                return;
                            }
                        });
                    }else{
                        this.$newapi.deviceUnbind({id:done.id}).then(res => {
                            console.log("解绑", res);
                            if (res.code == 200) {
                                this.init();
                            }else{
                                this.$message.error('解绑失败');
                                return;
                            }
                        });
                    }

                    console.log("点击了确定", _);

                })
        },
        //退出登录
        async LogOut() {
            console.log("LogOut");
            this.$message({
                message: "退出登录完成",
                type: "success",
                duration: 3 * 1000,
            })
            this.$store.commit('setUserInfo', {});
            window.localStorage.removeItem("userInfo");
            window.localStorage.removeItem("token");
            this.userInfo = {};
            this.$router.push("/");
            window.location.reload();
        },
        //页面显示，调init初始化页面数据
        show() {
            this.init();
        }
    }
}
</script>

<style></style>