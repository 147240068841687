<template>
    <div id="container">
    </div>
</template>

<script>
import { utlisApi } from "@/utils/utils";
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
    name: 'pictureDrag',
    data() {
        return {
            openid: '',
            option: {},
            utlisApi: new utlisApi(),
            tempcktDesign:null
        }
    },
    async mounted() {
        
        //判断是否有权限
        let isUse = await this.$utils.getaiCanUse();
        if(!isUse){
            this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
            return ;
        }
        let userinfo = window.localStorage.getItem('userInfo');
        userinfo = JSON.parse(userinfo);//this.$store.state.openid
        this.utlisApi.openid = userinfo.id;
        this.option = this.utlisApi.getpictureDrag();
        this.tempcktDesign = new CktDesign.createAiPainter(this.option);
        this.tempcktDesign.open();
    },
    methods: {
        init() {
            // return this.option;
        },
    },
    beforeRouteLeave (to, from, next) {
        // 插入离开钩子的逻辑代码
        this.tempcktDesign.close();
        next();
    }
}
</script>

<style scoped>
#Drag {
    width: 100%;
    height: calc(100vh - 100px );
}
</style>