<template>
    <Gateway/>
</template>

<script>
import Gateway from '@/components/Gateway/index.vue';
export default {
    data() {
        return {
            name: 'CardContent'
        }
    },
    components: {
        Gateway
    }
}
</script>

<style>

</style>