<template>
    <div class="pictureCard"  @click="execute_func(item)">
        <img class="pimg" :src="item.img" />
        <div class="ptitle">
            {{item.name}}
        </div>
        <input type="file" @change="handleFileUpload" ref="fileUpload" class="filetpy">
    </div>

</template>

<script>

import { utlisApi } from "@/utils/utils";
import CktDesign from "@chuangkit/chuangkit-design";//在需要使用的页面引入，并实例化对象

export default {
    name: 'CradPicture',
    props: {
        item: {
            type: Object,
            default: () => {
                return {
                    img:"https://top.xhsbds.com/assets/img/aiPictureTools/znkt.png",
                    name:"智能抠图",
                }
            }
        },
    },
    data() {
        return {
            temp: new utlisApi(),
            // CktDesign: new CktDesign(),
        }
    },
    methods: {
        /**
         * 判断中转执行那个方法
         */
        async execute_func(item){

            
            if(item.name == "智能抠图"){
                console.log("智能抠图");
                // console.log("智能抠图");
                // return ;
               await  this.AiMatting();
            }else if(item.name == "智能设计"){
                //判断是否有权限
                let isUse = await this.$utils.getaiCanUse();
                if(!isUse){
                    this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                    return ;
                }
                console.log("智能设计");
                this.AiDesign();
            }else{
                console.log("其他");
                item?.onitem();
            }
            console.log(item,"打印一下item")
         },
         /**
         * 调起智能设计
         */
        AiDesign(){
            let utlisApi = this.temp;
            let userinfo = window.localStorage.getItem('userInfo');
            userinfo = JSON.parse(userinfo);
            utlisApi.openid =  userinfo.id;
            let option = utlisApi.getAiDesign();
            let imageEditor = new CktDesign.createAiDesign(option);
            imageEditor.open();
        },
        /**
        * 调起智能抠图
        */
         AiMatting(){
            // 触发input点击事件
             this.$refs.fileUpload.click();
        },
        // 抠图编辑器的上传图片
        async handleFileUpload(event) {
            let selectedFile = event.target.files[0];
            console.log(selectedFile,"打印一下selectedFile");
            
                //判断是否有权限
                let isUse = await this.$utils.getaiCanUse();
                if(!isUse){
                    this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                    return ;
                }
                console.log("您没有权限使用AI功能",isUse);
            let utlisApi = this.temp;
            let userinfo = window.localStorage.getItem('userInfo');
            userinfo = JSON.parse(userinfo);
            utlisApi.openid =  userinfo.id;
            console.log(this.item.name,"打印一下this.item.name");
            
            if(this.item.name == "智能抠图"){
                let option = await utlisApi.getAiMatting({file:selectedFile});
                var AiMatt = new CktDesign.createMattingDesign(option);
                AiMatt.open();//打开抠图
                AiMatt.onSave(this.onSave);//保存事件
                AiMatt.onClose(this.onClose);//取消事件
                return ;
            }





 
        },
        /**
         * 抠图保存事件
         */
         onSave(result) {
            // result为返回的blob文件数据 可自行做操作
            // 以下为下载操作 ----
            let a = document.createElement('a')
            const imageUrl = window.URL.createObjectURL(result.slice(0, result.size, result.type))
            a.setAttribute('href', imageUrl)
            a.setAttribute('download', Date.now() + 'matting.png')
            a.click();
        },
        /**
         * 抠图取消事件
         */
        onClose(result) {
            // result为返回的数据对象，返回值说明如下
            // 参数名	必选	类型	说明
            // message	是	string	关闭来源  container_close-容器关闭
            console.log('close...', result)
        }
    },
}
</script>

<style lang="less" scoped>
.pictureCard{
    border: 1px solid #ffc2c2;
    // box-shadow: 0px 10px 20px 0px rgba(194, 145, 145, 0.4);
    border-radius: 20px;
    display: flex;
    flex-direction:column;
    justify-content: center;
    width: 100%;
    .pimg{
        width: 100%;
        height: 130px;
        object-fit: cover;
        object-position: center;
        border-radius: 20px 20px 0 0;
    }
    .ptitle{
        text-align: center;
        font-size: 16px;
        color: #333;
        background: #fff;
        padding: 20px 0;
        border-radius: 0 0  20px 20px;
    }
}
.pictureCard:hover{
    box-shadow: 0px 10px 20px 0px rgba(194, 145, 145, 0.677);
    color: #FE2043;
    border-radius: 20px;
}
.pictureCard:hover .ptitle{
    color: #FE2043;
}

.filetpy{
    display: none;
}
</style>