<template>
    <!-- :style="{ backgroundImage: 'url(' + item.image + ')' }" -->
    <div class="card-item" :class="{ 'card-left': index === 0, 'card-right': index === 3 }" @click="goTo(item)">
        <img :src="item.image" style="width: 100%;height:100%;position: absolute;z-index: -1;" />
        <div class="tcontent">
            <img :src="item.svg" class="icon" />
            <!-- <div class="icon" :class="item.icon"></div> -->
            <div>
                <div class="card-title">{{ item.value }}</div>
                <div class="card-doc">{{ item.description }}</div>
            </div>
        </div>
        <div class="card-link">
            <span style="font-size: 16px;">
                进入
            </span>
        </div>
    </div>
</template>

<script>
import image1 from '@/assets/home/index/fws.png';
import image2 from '@/assets/home/index/jlgl.png';
import image3 from '@/assets/home/index/bdsh.png';
import image4 from '@/assets/home/index/hydr.png';

// import { tempgoTo } from "@/utils/utils";
import { Message } from 'element-ui';

import  { getFingerprint } from '@/utils/utils.js';
export default {
    name: "CradHome",
    props: {
        item: {
            type: Object,
            default: () => {
                return {
                    "icon": "potato-icon",
                    "image": '@/assets/home/index/fws.png',
                    "value": "本地薯服务商共创平台",
                    "description": "为本地菌服务商提供优质服务",
                    "link": "进入"
                }
            }
        },
        index: Number

    },
    data() {
        return {
            dialogVisible: false,

            images: {
                image1,
                image2,
                image3,
                image4
            }
        }
    },
    methods: {
        async goTo(item) {
            console.log(item);
            if (item.icon == "experts-icon") {
                Message({
                    message: '即将上线，敬请期待',
                    type: "success",
                    duration: 3 * 1000,
                })
            } else if (item.icon == "potato-icon") {
                //检测是否登录
                let istemp = await this.getUserInfo();
                //判断是否是服务商
                if(istemp){
                    // item.link = `http://local.bds.com/luoye.php/index/login?url=%2Fluoye.php%2Fshop%2Findex%3Fref%3Daddtabs`
                    // console.log(item.link, "item.link");
                    // window.name = window.localStorage.getItem('token');
                    // window.open(item.link, "_blank"); 
                    
                    let token_uid = await this.$newapi.UserGoToken().then(res=>{
                        return res.data.token_uid
                    });
                    let  device_id = await getFingerprint();
                    item.link = item.link +`&token_uid=${token_uid}&device_id=${device_id}`
                    window.open(item.link, "_blank");
                }

            }
            else {
                // tempgoTo(item);
                console.log(item.link, "item.link");
                window.open(item.link, "_blank");
            }
        },
        handleClose() {
            this.dialogVisible = false;
        },
        open() {
            this.$prompt('请输入服务商授权码', '授权码', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
                inputErrorMessage: '近期即将上线，尽请期待'
            }).then(({ value }) => {
                this.$message({
                    type: 'success',
                    message: '授权码正确: ' + value
                });
            }).catch(() => {
                // this.$message({
                //     type: 'info',
                //     message: '取消输入'
                // });
            });
        },
        //根据token查询个人信息
        async getUserInfo() {
            let res = await this.$newapi.getUserInfo();
            console.log("res", res);
            if (res.code === 200) {
                let resnum = res.data.identity == 4 ? 2 : 3;
                this.$store.commit('setUserInfo', res.data);
                this.$store.commit('setLoginRole', resnum);
                window.localStorage.setItem('userInfo', JSON.stringify(res.data));
                window.localStorage.setItem('loginRole', resnum);
                // console.log(this.$store.state.userInfo, this.$store.state.loginRole, "this.$store.state.userInfo");
                if(res.data.identity == 4){
                    // 服务可以访问
                    return true;
                }else{
                    this.$message.error("您不是服务商无法访问");
                }
                return false;
            } else {
                this.$message.error(res.msg);
                return false;
            }
        },
    }
}
</script>

<style lang="less" scoped>
.tcontent {
    display: flex;
    margin: 20px;
}

.card-left {
    margin-left: 0;
}

.card-right {
    margin-right: 0;
}

.card-item {
    // background: #FFCBD3;
    cursor: pointer;
    position: relative;
    width: 100%;
    // border: 1px solid #fff;
    height: 234px;

    border-radius: 23px;

    background-size: 426px 235px;

    .card-img {
        transform: translate(5px, 5px);

        width: 120px;
        height: 120px;
        border-radius: 10px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .icon {
        width: 3em;
        height: 3em;
    }

    .card-title {
        line-height: 50px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
    }

    .card-doc {
        font-size: 14PX;
        color: #fff;
    }

    .card-link {
        position: absolute;
        bottom: 20px;
        left: 20px;
        padding: 10px;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 40px;
        width: 160px;

    }


}

.card-item img {
    width: 100%;
}

.card-item.card-item-title {
    font-size: 18px;
}

.card-item:hover {

    box-shadow: 0px 10px 20px 0px rgba(194, 145, 145, 0.677);

    .card-link {
        border-top: none;
        color: red;
        background: #fff;
        // border-radius: 0px 0px 10px 10px;
    }
}

//TODO: 适配问题
/* 当屏幕最大宽度小于1080px时的样式 */
@media screen and (max-width: 1080px) {
    .element {
        /* 根据需求设置对应的缩放比例 */
        transform: scale(0.8);
    }
}
</style>