<template>
    <div>
      <canvas ref="canvas" @click="generateCode"></canvas>
    </div>
  </template>
  
  <script>
  export default {
    data () {
      return {
        width: 100,
        height: 30,
        code: ''
      }
    },
    methods: {
      generateCode () {
        var canvas = this.$refs.canvas
        var ctx = canvas.getContext('2d')
        ctx.textBaseline = 'bottom'
  
        // 设置验证码宽度和高度
        canvas.width = this.width
        canvas.height = this.height
  
        this.code = '';
        var str = 'ABCDEFGHJKMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789'
        // 设置验证码字符，并加入数组
        for(let i=0; i<4; i++){
          let txt = str[this.randomNum(0,str.length)];
          this.code += txt;
          ctx.font = this.randomNum(this.height/2,this.height) + 'px SimHei'; 
          ctx.fillStyle = this.randomColor(50,160);
          ctx.shadowOffsetX = this.randomNum(-3,3);
          ctx.shadowOffsetY = this.randomNum(-3,3);
          ctx.shadowBlur = this.randomNum(-0,1);
          ctx.shadowColor = "rgba(0,0,0,0.0)";
          let x = this.width / 5 * (i + 1);
          let y = this.randomNum(this.height/2,this.height)
          let deg = this.randomNum(-25, 25);
          ctx.translate(x, y);
          ctx.rotate(deg * Math.PI / 180);
          ctx.fillText(txt, 0, 0);
          ctx.rotate(-deg * Math.PI / 180);
          ctx.translate(-x, -y);
        }
  
        // 设置验证码的干扰线
        for(let i=0; i<4; i++){
          ctx.strokeStyle = this.randomColor(40,180);
          ctx.beginPath();
          ctx.moveTo(this.randomNum(0,this.width),this.randomNum(0,this.height));
          ctx.lineTo(this.randomNum(0,this.width),this.randomNum(0,this.height));
          ctx.stroke();
        }
  
        // 设置验证码的干扰点
        for(let i=0; i<this.width/4; i++){
          ctx.fillStyle = this.randomColor(0,255);
          ctx.beginPath();
          ctx.arc(this.randomNum(0,this.width),this.randomNum(0,this.height), 1, 0, 2*Math.PI);
          ctx.fill();
        }
        // console.log(this.code)
      },
      randomNum(min, max) {
        return Math.floor(Math.random() * (max - min) + min);
      },
      randomColor(min, max) {
        var r = this.randomNum(min, max);
        var g = this.randomNum(min, max);
        var b = this.randomNum(min, max);
        return 'rgb(' + r + ',' + g + ',' + b + ')';
      }
    },
    mounted () {
      this.generateCode()
    }
  }
  </script>