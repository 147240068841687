<template>
    <div style="position: absolute;top: 0;left: 0;width: 100%;">
        <div class="head-bar">
            <CompanyLogo />
            <div class="input-bar" ref="navfliter">
                <div style="width: 50%;position: relative;">
                    <input class="nav-input" v-model="searchText" @input="filterList()" placeholder="搜索你想查找的模块..." @blur="handleBlur"/>
                    <ul class="navfliter" >
                        <li v-for="item in filteredList" :key="item.id" @click="navigateTo(item)">
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="nav">
                <!--  -->
                <NavUser  :user="user"/>
            </div>
        </div>
    </div> 
</template>

<script>

import CompanyLogo from '@/components/CompanyLogo';
import NavUser from '@/components/NavUser';
export default {
    name: 'NavigationBar',
    components: {
        NavUser, CompanyLogo
    },
    data() {
        return {
            searchText: '',
            // list: [
            //     { id: 1, name: 'Module 1', path: '/module1' },
            //     { id: 2, name: 'Module 2', path: '/module2' },
            //     { id: 3, name: 'Module 3', path: '/module3' },
            //     { id: 4, name: 'Module 4', path: '/module4' },
            //     // 其他模块...
            // ],
            list: [
                {
                    id: 1, name: "图文去重", doc: "高效智能美化图片", img: "https://top.xhsbds.com/assets/img/picture/twqc.svg",
                    path: '/pImageRemoval',
                },
                {
                    id: 2, name: "AI扩图", doc: "一键生成海量场景图", img: "https://top.xhsbds.com/assets/img/picture/AIkt.svg",
                    path: '/pictrueMod',
                },
                {
                    id: 3, name: "小红书主图", doc: "一键生成小红书头图", img: "https://top.xhsbds.com/assets/img/picture/xhszt.svg ",
                    path: '/xhs',
                },
                {
                    id: 4, name: "智能改图", doc: "一键修改，不留痕迹", img: "https://top.xhsbds.com/assets/img/picture/zngt.svg",
                    path: '/pictureUp',
                },
                {
                    id: 5, name: "变清晰", doc: "告别渣画质", img: "https://top.xhsbds.com/assets/img/picture/bqx.svg",
                    path: '/pclear',
                },
                // { id: 6, name: "智能抠图", doc: "3秒智能识别除背景", img: "https://top.xhsbds.com/assets/img/picture/znkt.svg" },
                // { id: 7, name: "图片编辑", doc: "轻松设计质感图片", img: "https://top.xhsbds.com/assets/img/picture/tpbj.svg" },
                // { id: 8, name: "智能设计", doc: "高效高质量", img: "https://top.xhsbds.com/assets/img/picture/AIznsj.svg" },
                {
                    id: 9, name: "商品设计", doc: "线稿秒变创意效果图", img: "https://top.xhsbds.com/assets/img/picture/AIspsj.svg",
                    path: '/pcommodity',
                },
                { id: 10, name: "祛除水印", doc: "在线智能祛除水印", img: "https://top.xhsbds.com/assets/img/picture/qcsy.svg" },
                {
                    id: 11, name: "模版中心", doc: "海量模版创作不用愁", img: "https://top.xhsbds.com/assets/img/picture/mbzx.svg",
                    path: '/pCenter',
                },
                {
                    id: 13, name: "文字工具/全部",
                    path: '/AITextTool?id=6',
                },
                {
                    id: 14, name: "文字工具/餐饮",
                    path: '/AITextTool?id=1',
                },
                {
                    id: 15, name: "文字工具/酒旅",
                    path: '/AITextTool?id=2',
                },
                {
                    id: 15, name: "文字工具/休闲娱乐",
                    path: '/AITextTool?id=3',
                },
                {
                    id: 15, name: "文字工具/电商",
                    path: '/AITextTool?id=4',
                },
                {
                    id: 15, name: "文字工具/医学美容",
                    path: '/AITextTool?id=5',
                }
            ],
            filteredList: [],
            user: {
                avatar: "https://top.xhsbds.com/assets/img/avatar.png",
                nickname: "测试账号"
            }
        };
    },
    methods: {
        handleBlur() {
            // 延迟200毫秒去清空filteredList, 以保证li的点击事件可以被正常处理
                setTimeout(() => {
                    this.filteredList = [];
                }, 200);
        },
        filterList() {
            this.filteredList = this.list.filter(item => {
                if (this.searchText == '') {
                    return [];
                }else if(this.searchText == ' '){
                    return true;
                
                } else {
                    return item.name.toLowerCase().includes(this.searchText.toLowerCase())
                }
            }
            );
        },
        navigateTo(item) {
            this.searchText = '';
            this.filteredList = [];
            this.$router.push(item.path);
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
    }
}
</script>

<style lang="less" scoped>
@height: 65px;

.head-bar {
    z-index: 100;
    background: #fff;
    box-sizing: border-box;
    position: fixed;
    height: @height;
    // background-color: #000;
    width: 100%;
    min-width: 850px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    padding-left: 20px;
    padding-right: 20px;

    .nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .input-bar {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
        // justify-content: center;
    }

    .nav-input {
        width: 100%;
        height: 40px;
        border-radius: 30px;
        font-size: 16px;
        // border: 1px solid #999999;
        border: 1px solid #ffc2c2;
        padding: 0 15px;

        outline: none;
        padding-left: 40px;
        box-sizing: border-box;
        background-image: url('~@/assets/home/search.svg');
        background-position: 10px center;
        /* 向右移动10px */
        background-repeat: no-repeat;
        // background-position: left;
        // padding-left: 40px; /* 根据你的图标大小调整 */
    }

    .navfliter {
        width: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        // border: 1px solid #FFCBD3;
        background: #fff;
        // box-shadow: 0px 4px 10px 0px #FFCBD3;
        border-radius: 10px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1575);
        padding: 0;
        margin: 0;

        li {
            // border-top: 1px solid #EEEEEE;
            list-style-type: none;
            padding-left: 20px;
            margin: 0;
            height: 40px;
            display: flex;
            align-items: center;
        }

        li:hover {
            background: #F5F5F5;
            cursor: pointer;
        }
    }
}</style>