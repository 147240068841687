<template>
        <div class="Titem-dom" @click="toTexturl(item)">
            <div style="display: flex;align-items: center;margin: 35px 0;">
                <div :class="item.icon"></div>
                <div class="">
                    <div style="font-size: 24px;padding: 10px 0;">{{ item.name }}</div>
                    <div style="font-size: 14PX;">{{ item.description ||"爆款文案等你来解锁" }}</div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            default:()=>{
                return {
                    icon: "Catering-icon",
                    name: "餐饮类",
                    description: "爆款文案等你来解锁",
                }
            }
        }
    },
    methods: {
        toTexturl(item) {
            this.$router.push({
                path: '/AITextTool',
                query: {
                    id: item.id
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.Titem-dom {
    
    border: 1px solid #ffc2c2;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 10px 20px 0px rgba(194, 145, 145, 0.677);
        color: #FE2043;
        border-radius: 20px;
    }
    .Timg{
        width: 90px;
        height: 90px;

        margin-right: 20px;
    }
}

</style>