<template>
    <el-container  >
        <!-- 导航条 -->
        <el-header>
            <NavigationBar />
        </el-header>
        <div class="elc0">
            <!-- 侧边栏 -->
            <el-aside style="width: 250px;position: fixed;">
                <!-- 封装一个侧边导航组件 -->
                <SideBar />
            </el-aside>
            <!-- 主体 -->
            <el-main style="padding-left: 250px">
                <RouterView class="rtv" style="min-width: 1320px;"/>
            </el-main>
        </div>

        <newLogin ref="newlogin"></newLogin>
        <Device></Device>
    </el-container>
</template>

<script>
import NavigationBar from "@/components/NavigationBar/index.vue";
import Device from "@/components/Device/index.vue";
import SideBar from "@/components/SideBar/index.vue";

import newLogin from '@/views/Login/newLogin.vue';
export default {
    name: 'newtemplate',
    components: {
        NavigationBar,SideBar,newLogin,Device
    },
    data() {
        return {
            role:localStorage.getItem("loginRole")
        }
    },
    methods:{
        async init(){
            console.log("init");
        }
    },
    // mounted(){
    //     console.log("newtemplate");
    //     this.init();
    // },
    // async init(){
    //     let res =  await this.$newapi.getUserInfo();
    //     console.log("res",res);
    //     if(res.code == 200){
    //         localStorage.setItem("userInfo",res.data);
    //         this.$store.commit('setUserInfo', res.data);
    //     }
    // }
}
</script>

<style lang="less" scoped>
.elc0{
    display: flex;
    flex-direction: row;
    .el-aside{
        width: 100px;
        // background-color: #545c64;
        color: #fff;
        text-align: center;
        height: calc(100vh - 65px);
    }
    .el-main{
        flex: 1;
        padding: 20px;
    }
}
</style>