<template>
    <div id="container">
    </div>
</template>

<script>
import { utlisApi } from "@/utils/utils";
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
    name: 'ImageEditing',
    data() {
        return {
            openid: '',
            option: {},
            utlisApi: new utlisApi()
        }
    },
    mounted() {
        this.init();
        console.log(this.option, "imageEditor");
        var imageEditor = new CktDesign.createImageDesign(this.option);
        imageEditor.importImage("https://test.xhsbds.com/img/20240121/1705818976.png")
        imageEditor.onSave(this.onSaved)
        imageEditor.onClose(this.onClose)   
    },
    methods: {
        init() {
            this.utlisApi.openid = "1234";
            this.option = this.utlisApi.getImageEditing();
            return this.option;
        },
        async urlToFile(url, filename = '你的文件名', mimeType ='image/png') {
            const res = await fetch(url);
            const buf = await res.arrayBuffer();
            return new File([buf], filename, { type: mimeType });
        },
        onSaved(result) {
            // Download the image
            const downloadLink = document.createElement('a');
            downloadLink.href = result['thumb-urls'][0];
            downloadLink.download = 'image.png';
            downloadLink.target = '_blank';
            downloadLink.click();
        },
        onClose(result){
        // result为返回的数据对象，返回值说明如下
        // 参数名	必选	类型	说明
        // message	是	string	关闭来源  container_close-容器关闭
            console.log('close...关闭', result);
            
            // 退出到首页
            this.$router.push('/index');
        }
    }
}
</script>

<style>
#imgtext {
    width: 100%;
    height: 100vh;
}
</style>