<template>
    <div class="container">
        <div class="header">
            <div class="back" @click="back">
                <img src="https://top.xhsbds.com/assets/img/back.png">
            </div>
            <div class="title">
                AI文字工具
            </div>
            <div class="sub-title">
                <!-- 火锅类 / 川渝火锅 / 探店体验   -->
                {{ title }}
            </div>
        </div> <br>

        <div class="content">


            <div v-html="temptext" class="desc" ref="desc" > </div>

            <div class="copy-button" @click="copyText">
                复制
            </div>

        </div>
<!-- @click="sendAgianMore" -->
        <div class="again-button" @click="open">
            再次生成
        </div>

        <div class="modal" v-if="showTextBox">
            <div class="modal-content">
                <!-- <input type="text" v-model="textInput"> -->

                <!-- <button @click="sendAgian">确定</button> -->
                <el-input placeholder="请输入内容" v-model="textInput" type="text" clearable>
                </el-input>
                <el-button round @click="cancel" class="send">取消</el-button>
                <el-button round @click="sendAgian" class="send">确定</el-button>

            </div>

        </div>


    </div>
</template>

<script>
import { aiChat } from '@/api/utils/index';

// 引入 GPTMsg.js
import GPTMsg from '@/utils/GPTMsg';
// import axios from 'axios'
export default {
    data() {
        return {
            textareaContent: '',
            title: '',
            GPTMsg: new GPTMsg(), //保存GPTMsg实例
            requestTask: null, //保存请求实例
            textInput: "",
            showTextBox: false,
            temptext:'',
            // description: '</br>给大家推荐一家非常好吃的火锅店！这家店不仅菜品新鲜，性价比也超高！而且最重要的是，它的环境超级适合拍照哦！\r\n\n首先，让我来给大家介绍一下这家烤肉店的环境。走进去就能感受到宽敞明亮的装修风格，温馨舒适。每个角落都充满了设计感，简直就像是拍照圣地！\n\n接下来说说菜品吧！这家烤肉店的菜品非常新鲜美味。无论是牛肉、羊肉还是鸡肉，都选用了上等优质食材。每一口都能感受到浓郁的香气和嫩滑多汁的口感，让人回味无穷！\n\n更重要的是，这里的的价格真心不贵哦！不仅菜品新鲜美味，而且性价比超高。你可以尽情享受美食，完全不用担心会花费太多钱。绝对是一个吃货们不能错过的的好地方！',
        };
    },
    methods: {
        ontype(item) {
            this.oncatetype = item;
            this.onAiChat();
        },
        async init() {
            // 获取url的数据
            let cateName = this.$route.query.cate;
            let track = this.$route.query.track;
            let drict = this.$route.query.drict;
            this.textareaContent = this.$route.query.textareaContent;

            this.title = `${cateName} / ${track} / ${drict}`;
            // console.log("this.$route.query", "路由数据");
            await this.onAiChat();
        },

        /**
         * 通过axios请求chat gpt 流式响应
         */
        async getStream() {
            try {
                let response = await aiChat({
                    msg: this.GPTMsg.getMsg(),
                    sign: this.GPTMsg.getSign(),
                    key: this.GPTMsg.getKey(),
                    timestamp: Math.floor(Date.now() / 1000),
                });
                // console.log(response, "获取内容");

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const reader = response.body.getReader();
                const textDecoder = new TextDecoder('utf-8');

                let accumulatedData = '';
                let result = true;

                while (result) {
                    const { done, value } = await reader.read();
                    if (done) {
                        console.log('Stream ended');
                        result = false;
                        break;
                    }

                    accumulatedData += textDecoder.decode(new Uint8Array(value));

                    // 查找并处理每个完整的数据块
                    let dataChunks = accumulatedData.split('\n\n');
                    for (let i = 0; i < dataChunks.length - 1; i++) {
                        const chunk = dataChunks[i];
                        // console.log(chunk, "获取内容");
                        if (chunk.includes('[DONE]')) {
                            this.handleEnd();
                            continue;
                        }

                        const jsonData = JSON.parse(chunk.replace(/^data: /, ''));

                        this.handleJsonChunk(jsonData);
                    }

                    accumulatedData = dataChunks[dataChunks.length - 1];
                }
            } catch (e) {
                console.log(e);
            }
        },

        handleJsonChunk(jsonData) {
            const {  choices } = jsonData;
            // console.log(id, "获取内容");
            if (choices && choices[0]) {
                const { content } = choices[0].delta;
                console.log(content, "获取内容");
                const that = this;
                if(content){
                    setTimeout(() => {
                    // that.temptext += content;
                    that.temptext += content.replace(/\n/g, '<br>');
                    this.GPTMsg.addMsg(content);
                    // const descElement = this.$refs.desc;
                    // descElement.innerHTML += content;
                }, 0);
                }

            }
        },

        handleEnd() {
            this.GPTMsg.setMsg(this.GPTMsg.roles[1], this.GPTMsg.msgs);
            this.GPTMsg.msgs = '';
        },






        back() {
            window.history.back();
        },

        cancel() {
            this.showTextBox = false;
            this.textInput = "";
        },

        sendAgianMore() {

            this.showTextBox = true;

        },
        async open() {
            let isUse = await this.$utils.getaiCanUse();
            if(!isUse){
                this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                return ;
            }

            this.$prompt('请输入对该文章的修改意见', '再次生成', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            }).then(({ value }) => {
                this.textInput = value;
                this.sendAgian();

            
            }).catch(() => {
                this.cancel();
                
            });
        },


        sendAgian() {
            if (this.GPTMsg.getMsgCount() >= 11) {
                alert("对话次数已达上限，请换个话题吧");
                // uni.showToast({
                // 	title: '对话次数已达上限，请换个话题吧',
                // 	icon: 'none',
                // 	duration:1500
                // });
                // return;
            }
            const inputText = this.textInput;
            console.log(inputText);
            this.GPTMsg.setMsg(this.GPTMsg.roles[0], inputText)

            this.temptext = '';//清空文本


            this.getStream();
            //请求接口
            console.log(5223320);

            // 隐藏文本框
            this.showTextBox = false;
            // 清空文本框中的文字
            this.textInput = "";
        },

        copyText() {
            // 创建一个textarea元素
            let textarea = document.createElement("textarea");

            // 设置其内容为你想要复制到剪贴板的文字
            // let text = "这是要复制的文本";
            let text = this.$refs.desc.innerText;
            textarea.value = text;

            // 将textarea元素添加到DOM
            document.body.appendChild(textarea);

            // 选中textarea元素的内容
            textarea.select();

            // 执行复制命令
            document.execCommand("copy");

            // 从DOM中移除textarea
            document.body.removeChild(textarea);

            this.$message({
                message: '内容已复制到剪贴板',
                type: 'success'
            });

            // 显示弹窗
            // alert("内容已复制到剪贴板");


        }
    },
    mounted() {
        let baseMsg = `你的任务是以小红书博主的文章结构，以我给出的主题写一篇帖子推荐。
        你的回答应包括使用表情符号来增加趣味和互动，
        以及与每个段落相匹配的图片。请以一个引人入胜的介绍开始，为你的推荐设置基调。然后，提供至少三个与主题相关的段落，
        突出它们的独特特点和吸引力。在你的写作中使用表情符号，使它更加引人入胜和有趣。对于每个段落，
        请提供一个与描述内容相匹配的图片。这些图片应该视觉上吸引人，并帮助你的描述更加生动形象。我给出的主题是：${this.$route.query.textareaContent}`
        this.GPTMsg.setMsg(this.GPTMsg.roles[0], baseMsg)
        this.getStream();
        // this.init();

        // document.body.style.backgroundColor = '#FFF0F0';

    },

}
</script>

<style scoped>
.send {
    position: relative;
    float: right;
    margin-top: 5%;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
}



.header {
    display: flex;
    flex-direction: row;
    /* border: 1px red solid; */
}

.header .back {
    display: flex;
    width: 50px;
    height: 50px;
    opacity: 1;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}


.title {
    margin-left: 2%;
    /* 设置右侧的间距为20像素 */
    font-size: 24px;
    font-weight: normal;
    line-height: 41px;
    letter-spacing: 0px;
    font-family: AlibabaPuHuiTi;
    color: #333333;
    opacity: 1;

}

.sub-title {
    display: flex;
    margin-left: 2%;
    /* 设置右侧的间距为20像素 */
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    text-align: center;
    align-items: center;
    justify-content: center;

}

.content {
    /* border: 1px red solid; */
    position: relative;
}


.desc {
    position: relative;
    /* width: 1166px; */
    max-height: 590px; /* 添加此行以限制高度并触发滚动条 */
    overflow-y: auto; /* 添加此行以显示垂直滚动条 */
    height: 590px;
    border-radius: 24px;
    background-color: #ffffff;
    color: #666666;
    padding: 25px;
    border: 1px solid #918d8b;

}

.copy-button {
    position: absolute;
    bottom: 0px;
    /* margin-right: 0px; */
    right: 0;
    color: red;
    margin-right: 2%;
    margin-bottom: 1%;
}



.again-button {
    display: flex;
    margin-top: 20px;
    /* border: 1px red solid; */
    /* position: absolute; */
    left: 0px;
    top: 812px;
    /* width: 1166px; */
    height: 68px;
    border-radius: 16px;
    opacity: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background: linear-gradient(131deg, #FFD06E -28%, #FE2043 52%);
    font-size: 24px;

}

.container {
    width: 60%;
    margin: auto;
    min-width: 800px;
}
</style>