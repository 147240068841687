<template>
    <div id="container">
    </div>
</template>

<script>
// import { utlisApi } from "./text";
import { utlisApi } from "@/utils/utils";
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
    name: 'AiPainter',
    data() {
        return {
            openid: '',
            option: {},
            utlisApi: new utlisApi()
        }
    },
    mounted() {
        this.init();
        console.log(this.option, "imageEditor");
        var imageEditor = new CktDesign.createAiPainter(this.option);
        imageEditor.open();
    },
    methods: {
        init() {
            this.utlisApi.openid = "1234";
            this.option = this.utlisApi.getAiPainter({mParam: 5});
            return this.option;
        },
    }
}
</script>

<style>
#container {
    width: 100%;
    height: 100vh;
}
</style>