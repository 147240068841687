<template>
    <div class="container">

        <div class="left">
            <div class="title">
                去除水印
            </div>


            <div class="outer-div">
                <span>*请输入图片/视频链接</span>

                <textarea class="more" v-model="more" resize="none" placeholder="请输入">

                </textarea>

            </div>

            <div class="end" @click="send" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
                <span class="end-text">开始解析</span>
            </div>

        </div>

        <div class="right">

            <div class="modal" v-if="showTextBox">
                <div class="modal-container">

                    <div class="modal-content">
                        <img src="https://top.xhsbds.com/assets/img/up.png ">
                    </div>

                    <span class="mix">生成的图片/视频将会显示在这里</span>
                </div>
            </div>


            <div class="modal" v-else>

                <div class="power-copy">
                    <span>文案</span>

                    <div class="titleCharacters" ref="titleChars">
                        {{ maincontent }}
                    </div>

                    <div class="copy-button" @click="copyText">
                        复制
                    </div>
                </div>


                <div class="end-div" v-if="showImgBox">

                    <Stick :list="pics" @onScrollEnd="loadMore">
                        <template slot-scope="scope">
                            <div class="card" style="position: relative;">
                                <img :src="`https://top.xhsbds.com/imageUrl/?url=${scope.data}`" class="scopeimg" />
                                <div class="scopemask">
                                    <!-- 添加点击事件，点击打开tab查看图片 -->
                                    <div class="scopebtn" style="color: #fff;" @click="openImage(scope.data)">查看原图</div>
                                    <div class="scopebtn" style="color: #fff;" @click="download(scope.data)">下载图片</div>
                                </div>
                            </div>
                        </template>
                    </Stick>

                </div>

                <div class="end-div" v-else>


                    <div class="end-div-video">
                        <div class="flex-container">
                            <div class="video-container">
                                <img :src="`https://top.xhsbds.com/imageUrl/?url=${imageUrl}`" alt="" class="video-img">

                            </div>
                            <div class="button-container">
                                <el-button type="danger" @click="copyVideo">复制</el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>

    </div>
</template>


<script>
import { parsing } from '@/api/utils/index';
import Stick from 'vue-stick';

export default {
    components: {
        Stick: Stick.component
    },
    data() {
        return {
            more: '',
            showTextBox: true,
            showImgBox: true,
            url: '',
            titleCharacters: '',
            maincontent: "",
            pics: [],
            video: '',
            imageUrl: '',
            showMask: false,
        }
    },
    created() {

        this.more = '';
    },
    methods: {
        async send() {
            let isUse = await this.$utils.getaiCanUse();
            if(!isUse){
                this.$message.error('您没有权限使用AI功能，请检查是否登录/小程序开通套餐');
                return ;
            }
           
            // console.log(this.more, "列表数据");

            // 使用正则表达式从this.more中提取URL
            const urlRegex = /(http|https):\/\/[^\s"']+/g;
            let match = this.more.match(urlRegex);
            console.log(match, "match");

            if (match && match.length > 0) {
                let url = match[match.length - 1]; // 获取最后一个匹配的URL


                let list = await parsing({ url: url });


                console.log(list, "总数据");
                // 更新
                if ('voideurl' in list.data) {
                    this.showTextBox = false;
                    //视频 
                    this.maincontent = list.data.voidename;
                    this.showImgBox = false;
                    // this.video = list.data.voideurl;

                    // this.video = `https://top.xhsbds.com/imageUrl/?url=${list.data.voideurl}`;
                    this.video = list.data.voideurl;

                    this.imageUrl = list.data.photo;
                    console.log(this.video, "video");

                } else {
                    this.showTextBox = false;
                    this.showImgBox = true;


                    //pic
                    this.maincontent = list.data.voidename;
                    this.pics = list.data.pics;
                }
            } else {
                this.$message({
                    message: '未能从文本中找到有效的URL',
                    type: 'erro'
                });
            }

        },
        copyVideo() {
            // 创建一个textarea元素
            let textarea = document.createElement("textarea");

            // 设置其内容为你想要复制到剪贴板的文字
            // let text = "这是要复制的文本";
            let text = this.video;
            textarea.value = text;

            // 将textarea元素添加到DOM
            document.body.appendChild(textarea);

            // 选中textarea元素的内容
            textarea.select();

            // 执行复制命令
            document.execCommand("copy");

            // 从DOM中移除textarea
            document.body.removeChild(textarea);

            this.$message({
                message: '内容已复制到剪贴板',
                type: 'success'
            });

        },

        onMouseEnter() {
            this.$refs.videoContainer.classList.add('hovered');
        },
        onMouseLeave() {
            this.$refs.videoContainer.classList.remove('hovered');
        },

        copyText() {
            // 创建一个textarea元素
            let textarea = document.createElement("textarea");

            // 设置其内容为你想要复制到剪贴板的文字
            // let text = "这是要复制的文本";
            let text = this.$refs.titleChars.innerText;
            textarea.value = text;

            // 将textarea元素添加到DOM
            document.body.appendChild(textarea);

            // 选中textarea元素的内容
            textarea.select();

            // 执行复制命令
            document.execCommand("copy");

            // 从DOM中移除textarea
            document.body.removeChild(textarea);

            this.$message({
                message: '内容已复制到剪贴板',
                type: 'success'
            });

        },
        // 点击图片时在新窗口中查看大图
        viewLarge(pic) {

            window.open(pic);
        },
        // 视频点击
        openVideo(url) {
            console.log("url", url, "url");
            // var newWindow =   window.open(url, '_blank');
            // newWindow.location.reload();
            // newWindow.onload = function() {

            //     console.log("url2", newWindow, "url2");
            // };
            var link = document.createElement('a');
            link.href = url;
            // link.target = '_blank';
            // link.innerHTML = '打开新页面';
            document.body.appendChild(link);

            console.log('页面加载完成');
            link.addEventListener('load', function () {
                link.contentWindow.location.reload();
                console.log('页面加载完成');
                link.remove();
            });
        },
        /**
         * 下载视频
         * @param {*} video 
         */
        downVideo(video) {
            let end = `https://top.xhsbds.com/imageUrl/?url=${video}`;

            const link = document.createElement('a');
            link.href = end;
            link.download = 'video.mp4';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);


            // console.log(1111);
            // console.log(end);
        },

        download(url) {
            // process.env.VUE_APP_Server
            const proxiedImageUrl = window.appConfig.baseUrl[process.env.NODE_ENV] + '/imageUrl/?url=' + url;
            // const proxiedImageUrl = 'http://show.xhsbds.com/imageUrl/?url=' + url;
            fetch(proxiedImageUrl)
                .then(response => response.blob())
                .then(blob => {
                    // 创建一个Blob URL
                    const blobUrl = URL.createObjectURL(blob);

                    // 创建一个a元素并设置下载链接
                    let a = document.createElement('a');
                    a.href = blobUrl;
                    a.download = Date.now() + 'matting.png';

                    // 模拟点击a元素进行下载
                    a.click();

                    // 释放Blob URL，以防内存泄漏
                    URL.revokeObjectURL(blobUrl);
                })
                .catch(error => {
                    console.error('下载失败：', error);
                });


        },
        loadMore: function () {
            // 滑倒底部时，加载更多执行该方法
        },
        // 查看原图
        openImage(url) {
            let pics = window.appConfig.baseUrl[process.env.NODE_ENV] + `/imageUrl/?url=${url}`;
            window.open(pics, '_blank');
        },

    },
    mounted() {
        // this.init();
        // document.body.style.backgroundColor = '#FFF0F0';

    },


}
</script>


<style scoped>
.container {
    /* position: fixed; */
    /* width: 100%; */
    height: calc(100vh - 100px);
    /* min-height: 600px; */
    /* border: 1px solid red; */
    display: flex;
    box-sizing: border-box;
    /* padding: 30px; */
}


.left {
    position: relative;
    /* width: 20%; */
    width: 20vw;
    min-width: 456px;
    height: 100%;
    background: #FFFFFF;
    display: flex;
    padding-top: 50px;
    /* 添加内边距以容纳.title */
    flex-direction: column;
    /* 添加此行以使flex子元素垂直排列 */
    align-items: center;
    /* 添加此行以实现垂直居中 */
    /* justify-content: center; */

    /* border: 1px solid rgb(86, 84, 187); */
}



.end {
    position: absolute;
    /* 将.end改为绝对定位 */
    bottom: 20px;
    /* 设置元素紧贴底部 */
    margin-bottom: 20px;
    /* border: 1px red solid; */
    max-width: 368px;
    width: 368px;
    height: 68px;
    border-radius: 7px;
    opacity: 1;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    color: #FFFFFF;
    background: linear-gradient(131deg, #FFD06E -28%, #FE2043 52%);
    font-size: 24px;

    cursor: pointer;




}

.end-text {
    font-size: 24px;
    color: #FFFFFF;

    /* 可选：清除可能影响居中的默认内外边距 */
    margin: 0;
    padding: 0;
}


.outer-div {
    margin-top: 2%;
    position: relative;
    background: #F3F4F9;
    border-radius: 10px;
    width: 100%;
    max-width: 400px;
    /* 根据需要设置最大宽度 */
    height: 256px;
    /* 根据需要设置高度 */
    display: flex;
    /* justify-content: center; */
    text-align: left;
    /* 新增样式：设置文字左对齐 */
    flex-direction: column;
    /* 添加此行以实现垂直布局 */


}

.outer-div span {
    margin-left: 2%;
    margin-top: 1%;
}

.outer-div span {
    margin-bottom: 10px;
    /* 添加此行以在span与textarea之间增加间距 */
    /* border: 1px solid rgb(86, 84, 187); */
}


.modal span {
    /* margin-left: 5%; */
    font-family: AlibabaPuHuiTi;
    font-size: 16px;
    font-weight: normal;
    line-height: 29px;
    letter-spacing: 0px;
    color: #333333;


}


textarea.more {
    resize: none;
    color: #666666;
    border-radius: 6px;
    display: block;
    /* 将display属性更改为block以去除flex对齐的影响 */

    max-width: 380px;
    height: 180px;
    width: 100%;
    opacity: 1;
    /* 添加以下样式实现居中 */
    margin-left: auto;
    margin-right: auto;
    background: #FFFFFF;
    /* border: 1px solid #CCCCCC; */
}



.title {
    position: absolute;
    /* 将.title改为绝对定位 */
    top: 0;
    left: 6%;
    font-size: 20px;
    font-weight: normal;
    line-height: 29px;
    letter-spacing: 0px;
    font-family: AlibabaPuHuiTi;
    color: #333333;
    opacity: 1;

}



.right {
    background: #F8F8FB;
    position: relative;
    height: calc(100vh - 100px);
    /* width: 80%; */
    flex: 1;
    /* border: 1px solid rgb(185, 152, 40); */
}


.modal {
    /* border: 1px rgb(26, 24, 24) solid; */
    /* https://top.xhsbds.com/assets/img/up.png */
    width: 100%;
    height: 100%;

    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    /* margin-bottom: 20px; */
}

.modal-container {
    display: flex;
    align-items: center;
    /* 垂直居中 */
    justify-content: center;
    /* 水平居中 */
    gap: 16px;
    /* 添加间距，根据需要调整 */
    margin-top: 20%;

}

.modal-content {

    /* margin-top: 20%;
    margin-left: 7%; */
    width: 483px;
    height: 273px;
    /* border: 1px red solid; */
}

.title-div {
    height: 150px;
    /* border: 1px rgb(192, 106, 106) solid; */
    position: relative;
}

.power-copy {
    min-height: 150px;
    /* border: 1px rgb(192, 106, 106) solid; */
    padding: 5px;
    position: relative;

}









.titleCharacters {
    /* display: flex; //使用Flex布局 
    justify-content: center;//水平居中 
    align-items: center;//垂直居中 */
    /* padding: 10px; */

    /* border: 1px solid black; */
    min-height: 80px;
    padding: 4px;
    box-sizing: border-box;
    /* 确保包含边框和内填充在内的总宽度不超过父元素的90% */

}



.copy-button {
    position: absolute;
    bottom: 0px;
    /* margin-right: 0px; */
    right: 0;
    color: red;
    margin-right: 2%;
    margin-bottom: 1%;

}




.end-div-video {
    height: auto;
    width: 100%;
    /* border: 1px rgb(192, 106, 106) solid; */
    background: #F3F4F9;
    border-radius: 10px;
    position: relative;
}


.flex-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.video-container {
    /* border: 1px rgb(192, 106, 106) solid; */
    height: 558px;
    width: 100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F3F4F9;
    border-radius: 6px;
    cursor: pointer;
    margin: 0 auto;
    /* 居中对齐 */
}





.video-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}


.button-container {
    position: absolute;
    top: 2%;
    right: 3%;
}



/* .end-div-video .image-container { */
.image-container {
    position: relative;
    height: 558px;
    width: 374px;

    opacity: 1;
    border: 1px solid rgb(116, 113, 113);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #F3F4F9;
    /* background: #F3F4F9; */

    border-radius: 6px;
    cursor: pointer;

    /* .video-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    } */




}

video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
}







.scopeimg {
    width: 100%;
    border-radius: 16px;
    border: 1px solid #fff;
    box-sizing: border-box;
}

/* // 鼠标移入,添加灰色蒙版 */
.scopeimg:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.card:hover .scopemask {
    visibility: visible;
}

.scopemask {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4969);
    ;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* justify-content: flex-end; */
    /* 更改为靠下排列 */

}

.card:hover .scopemask {
    visibility: visible;
}

.scopebtn {
    width: 100px;
    height: 40px;
    /* background-color: #fff; */
    color: red;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #000;
    cursor: pointer;
    /* margin-bottom: 20px; */
    /* 添加底部外边距以调整间距，数值可自定义 */
}




.mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}


.mask-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px;
    box-sizing: border-box;
}



.mask-actions button {
    margin-right: 10px;
}
</style>