<template>
  <div id="home">
    <img src="~@/assets/image/bg.png" alt="bg" class="bg">
    <div style="position: absolute;top: 0;left: 0;width: 100%;">
      <div class="head-bar">
      <CompanyLogo />
      <div class="nav">
        <NavUser :user="user" />
      </div>
    </div>
    </div>

    <div class="titlebar" style="margin-top: 80px;">管理后台</div>
    <!-- 跳转地址 -->
    <div class="card" style="height: 268px;">
      <CradHome v-for="(item, i) in this.userMenu?.managerSystemList" :key="i" :item="item"  />
    </div>
    <div class="titlebar">本地生活AI图片创作工具</div>
    <div style="display:flex;justify-content: space-between;height: 130px;">
      <TopSiteHome v-for="(item,i) in picAI" :key="i"  :itemAI="item" />
    </div>
    <!-- <div class="outer-container">
      <div class="contentbar">
        <TopSiteHome v-for="(item,i) in picAI" :key="i"  :itemAI="item"/>
      </div>
    </div> -->
    <div class="titlebar" >本地生活爆文创作工具</div>
    <div style="display:flex;justify-content: space-between;height: 130px;">
      <TopSiteHome v-for="(item,i) in docAI" :key="i"  :itemAI="item" />
    </div>

    <!-- <div class="outer-container">
      <div class="contentbar">
        <TopSiteHome v-for="(item,i) in docAI" :key="i"  :itemAI="item" />
      </div>
    </div> -->
    <!-- <div class="contentbar">

      <TopSiteHome v-for="i in 10" :key="i" />
    </div> -->
    <div class="titlebar">免费工具</div>
    <div class="outer-container">
      <div class="inner-container">
        <FreeToolsHome v-for="(item,i) in thirdToolList" :key="i"  :toolsList="item" />
      </div>
    </div>

  </div>
</template>

<script>
import CradHome from '@/components/CradHome';
import CompanyLogo from '@/components/CompanyLogo';
import NavUser from '@/components/NavUser';
import TopSiteHome from '@/components/TopSiteHome';
import FreeToolsHome from '@/components/FreeToolsHome';
import { getUserMenu } from '@/api/utils/index';

export default {
  data() {
    return {
      user: {
        avatar: "/assets/img/avatar.png",
        nickname: "测试账号"
      },
      platform: [
        {
          "img": "local_potato_service_provider",
          "value": "本地薯服务商共创平台",
          "description": "为本地菌服务商提供优质服务",
          "link": "进入"
        },
        {
          "img": "wine_travel_service_provider",
          "value": "酒旅服务商共创平台",
          "description": "为酒旅服务商提供优质服务",
          "link": "进入"
        },
        {
          "img": "local_lifestyle_service_provider",
          "value": "本地生活服务商管理后台",
          "description": "为本地生活服务商提供优质服务",
          "link": "进入"
        },
        {
          "img": "quality_service_for_experts",
          "value": "为达人提供优质服务",
          "description": "达人招募服务中心",
          "link": "进入"
        }
      ],
      userMenu:{},
      docAI:[],
      picAI:[],
      thirdToolList:[]
    }
  },
  created() {
    this.init();
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  // CardHome
  components: {
    CompanyLogo, NavUser, CradHome, TopSiteHome, FreeToolsHome
  },
  methods:{
    init(){
      getUserMenu().then(res => {
        this.userMenu = res.data;
        this.docAI = res.data?.toolList?.ownToolList?.docAI;
        this.picAI = res.data?.toolList?.ownToolList?.picAI;
        this.thirdToolList = res.data?.toolList?.thirdToolList;
      })
    },

  }
}
</script>

<style lang="less" scoped>
@left-width: 150px;
@line-height: 65px;

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

#home {

  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
  /* 将高度设置为视口高度 */
}


.head-bar {
  z-index: 100;
  background: #fff;
  box-sizing: border-box;
  position: fixed;
  height: @line-height;
  // background-color: #000;
  width: 100%;
  min-width: 850px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  .nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.titlebar {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 20px;
  font-family: Source Han Sans, Source Han Sans;
  font-weight: 600;
  color: #3D3D3D;
}

.contentbar {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 20px;
}

.outer-container {
  display: grid;
}

.inner-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  /* 可选：设置间距 */
}



</style>