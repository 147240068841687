<template>
  <div id="container" style="height: 100vh;">
  </div>
</template>

<script>
import { utlisApi } from "@/utils/utils";
//在需要使用的页面引入，并实例化对象
import CktDesign from "@chuangkit/chuangkit-design";
export default {
  name: 'Poster',
  data() {
    return {
      openid: '',
      option: {},
      utlisApi: new utlisApi()
    }
  },
  mounted() {
    this.init();
    console.log(this.option, "打印一下option");
    this.design = new CktDesign.createTemplateCenter(this.option);
    this.design.open().then(() => {
      this.design.onParamsChange(this.onParamsChange)
      this.design.onChooseTemplate(this.onChooseTemplate)

      // 关闭模板中心
      // instance.close();

      // 动态调整筛选项
      // @params { IParams } params 筛选项
      // instance.setParams(params)
    })
  },   
  methods:{
      init(){
        this.utlisApi.openid = "1234";
          // 点击拉起
          const myparams = {
            settingCode: "NLA7",
          }
          this.option = this.utlisApi.getDesignCenter(myparams);
          return this.option;
      },
      // 筛选项变更时触发---
      // @params { Iparams } params 筛选项
      onParamsChange(params) {
        console.log("筛选项变更时触发",params)
      },
      // 用户点击模板时触发---
      // @params { ITemplateItem } templateItem 模板项
      onChooseTemplate(templateItem){
        console.log( templateItem ,"用户点击了模板")
      }
    }

}
</script>

<style></style>