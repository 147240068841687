import axios from 'axios'
import { Message } from 'element-ui';
// import config from '@/api/config.js'


var api = axios.create({
    // baseURL:process.env.VUE_APP_Server,//请求后端地址
    // baseURL: window.appConfig.baseUrl[process.env.NODE_ENV],//请求后端地址
    // baseURL: config.baseUrl[process.env.NODE_ENV],//请求后端地址
    // timeout: 7000,//请求超时
    withCredentials: true, // 是否发送 cookie
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
});


// 请求拦截器
api.interceptors.request.use(
    config => {
        config.baseURL = window.appConfig.baseUrl[process.env.NODE_ENV];//请求后端地址
        // console.log("哈哈哈哈",config);
        // 请求发送前做一些处理
        // 每个请求携带token
        // config.headers['Authorization'] = `Bearer asldfawelagalrkna.alwrgkja;lwegnafjasld.fjfawegaefaregieg`
        // if (localStorage.getItem('token') != null) {
        //     // config.headers['HTTP_TOKEN'] = `${localStorage.getItem('token')}`
        //     config.headers['Authorization'] = `${localStorage.getItem('token')}`
        // }
        return config;
    },
    error => {
        console.log(error, "请求拦截");
        // 请求出错时处理
        return Promise.reject(error);
    }
);

// 响应拦截器
api.interceptors.response.use(
    /**
     * 如果需要获取 http 信息如 headers 或者 status
     * 返回 response => response
     */
    response => {
        
        // console.log(response.headers.authorization,"响应拦截");
        //判断是否存在Authorization 和loncstorage中的token是否一致
        // if(response.headers.authorization && response.headers.authorization !== localStorage.getItem('token')){
        //     localStorage.setItem('token',response.headers.authorization);
        //     console.log("更新咯");
        // }

        /**
         * 根据自定义代码判断请求状态
         * 下面只是一个示例
         * 你也可以根据 HTTP Status Code 判断状态
         */
        // 响应数据处理
        const res = response.data;
        // console.log("获取登录信息",res);
        // 20000 是服务器返回的 code
        if (response.status === 200) {
            return res
        } 
        // else if (response.status === 302) { 
        //     Message.warning(res.msg);
        //     return Promise.reject(res.msg);
        // }
        else {
            Message.warning('联系管理员');
            // 响应错误处理
            Promise.reject(new Error(res.msg || 'Error'));

        }


    },
    error => {
        //网络超时异常处理
        if (error.code === 'ECONNABORTED' || error.message === "Network Error" || error.message.includes("timeout")) {
            // 超时处理,引入ellement-ui message组件
            Message({
                message: '请求超时，请稍后重试',
                type: "error",
                duration: 3 * 1000,
            })
        }
        return Promise.reject(error);
    }
);

export default api;