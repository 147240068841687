<template>
  <div id="free-card">
    <div style="margin: auto;" @click="goTo(toolsList)">
      <div>{{ toolsList.name }}</div>
      <div style="color: #999999;font-size: 14px;padding-top: 10px">{{toolsList.intro}}</div>
    </div>
  </div>
</template>

<script>

import {tempgoTo} from "@/utils/utils"
export default {
  props: {
    toolsList: {
      type: Object,
      default: () => {
        return {
          icon: "",
          intro: "功能丰富的图片在线编辑",
          name: "PhotoKit",
          status: 1,
          url:"https://photokit.com/"
        }
      }
    }
  },
  data() {
    return {
    }
  },
  methods: {
    goTo(item) {
      tempgoTo(item);
    }
  }
}
</script>

<style lang="less">
#free-card {
  cursor: pointer;
  height: 60px;
  background: #F9F9F9;
  border-radius: 10px 10px 10px 10px;
  border:1px solid #F9F9F9;
  padding: 20px;
  font-size: 16px;
}
</style>